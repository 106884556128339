import { Controller } from "@hotwired/stimulus";

// This Stimulus controller manages dynamic updates for onboarding configuration
export default class extends Controller {
  static targets = [
    "accountWorkflows",
    "accountWorkflowsLink",
    "categoryActive",
    "categoryFilters",
    "categoryInactive",
    "dayOneFormsBox",
    "dayOneFormsLabel",
    "directDepositBox",
    "employeeTypeRadio",
    "preloadedWorkflows",
    "preloadedWorkflowsLink",
    "workflowDiv"
  ];

  static values = {
    label: Object,
    directDepositId: String,
    federalW9Id: String,
    federalI9Id: String
  };

  connect() {
    this.updateFormLabel();
    this.toggleDirectDeposit();
  }

  // Update form label based on the selected employee type
  updateFormLabel() {
    const selectedType = this.selectedEmployeeType();
    const labelText = this.labelValue[selectedType];
    this.dayOneFormsLabelTarget.textContent = labelText;
    this.displayDefaultTaxWorkflow(selectedType);
  }

  // Decide which initial tax workflow to show based on selected employee type
  displayDefaultTaxWorkflow(selectedType) {
    this.toggleTaxFormWorkflow(selectedType);
  }

  // Hide workflow when the remove button is clicked
  removeWorkflow(event) {
    const workflowDiv = event.target.closest("[data-onboarding-config-target='workflowDiv']");
    if (workflowDiv) {
      workflowDiv.classList.add("d-none");
    }
  }

  // Toggle visibility of the direct deposit workflow based on checkbox
  toggleDirectDeposit() {
    const element = document.getElementById(`workflow-container-${this.directDepositIdValue}`);
    this.toggleElementVisibility(element, this.directDepositBoxTarget.checked);
  }

  // Toggle visibility of the Day One Tax Forms workflow based on checkbox
  toggleDayOneForms() {
    const selectedType = this.selectedEmployeeType();
    this.toggleTaxFormWorkflow(selectedType);
  }

  // Remove the direct deposit workflow when clicked
  handleDirectDeposit(event) {
    this.directDepositBoxTarget.checked = false;
    this.removeWorkflow(event);
  }

  // Remove the Day One Tax Forms workflow when clicked
  handleDayOneForms(event) {
    this.dayOneFormsBoxTarget.checked = false;
    this.removeWorkflow(event);
  }

  // Action triggered on modal click event
  addWorkflows() {
    const addWorkflowsContainer = document.getElementById('add-workflows-container');
    if (addWorkflowsContainer) {
      const checkboxes = addWorkflowsContainer.querySelectorAll('input[type="checkbox"]:checked');
      checkboxes.forEach(checkbox => {
        const taskSetId = checkbox.id.replace('add_workflow_', '');
        this.unhideWorkflow(taskSetId);
      });
    }
  }

  // Toggles the visibility of the "My Workflows" container
  showAccountWorkflows() {
    this.accountWorkflowsTarget.classList.remove("d-none");
    this.preloadedWorkflowsTarget.classList.add("d-none");
    this.setActiveTab(this.accountWorkflowsLinkTarget);
  }

  // Toggles the visibility of the "Preloaded Workflows" container
  showPreloadedWorkflows() {
    this.accountWorkflowsTarget.classList.add("d-none");
    this.preloadedWorkflowsTarget.classList.remove("d-none");
    this.setActiveTab(this.preloadedWorkflowsLinkTarget);
  }

  // Toggle visibility of categories when clicked
  toggleCategoryFilter(event) {
    const targetBadge = event.target.closest(".badge");
    targetBadge.classList.toggle("badge-primary");
    targetBadge.classList.toggle("badge-light");
    const icon = targetBadge.querySelector("i");
    icon.classList.toggle("fa-times-circle");

    // Check if any badges are now "badge-primary"
    this.checkIfBadgePrimary();
    const category = targetBadge.parentElement.dataset.category;
    this.toggleCategoryWorkflowList(category, targetBadge);
  }

  private

  // Get the value of the selected employee type radio button
  selectedEmployeeType() {
    const selectedRadio = this.employeeTypeRadioTargets.find(radio => radio.checked);
    return selectedRadio ? selectedRadio.value : null;
  }

  // Toggle tax form workflows based on selected employee type and checkbox state
  toggleTaxFormWorkflow(selectedType) {
    const w9Element = document.getElementById(`workflow-container-${this.federalW9IdValue}`);
    const i9Element = document.getElementById(`workflow-container-${this.federalI9IdValue}`);

    if (selectedType === "contractor") {
      this.toggleElementVisibility(w9Element, this.dayOneFormsBoxTarget.checked);
      this.toggleElementVisibility(i9Element, false);  // Hide I-9
    } else {
      this.toggleElementVisibility(i9Element, this.dayOneFormsBoxTarget.checked);
      this.toggleElementVisibility(w9Element, false);  // Hide W-9
    }
  }

  // Toggle visibility of an element based on a condition
  toggleElementVisibility(element, isVisible) {
    if (element) {
      element.classList.toggle("d-none", !isVisible);
      this.toggleTaskBoxes(element);
    }
  }

  // Display the workflow when added from the modal
  //
  // Selects all divs with the matching id and only adds if none of the other matchings one are visible
  unhideWorkflow(taskSetId) {
    const workflowDivs = document.querySelectorAll(`#workflow-container-${taskSetId}`);
    const anyVisible = Array.from(workflowDivs).some(workflowDiv => !workflowDiv.classList.contains("d-none"));
    if (!anyVisible) {
      workflowDivs[0].classList.remove("d-none");
      this.toggleTaskBoxes(workflowDivs[0]);
    }
  }

  // Uncheck the boxes for each individual task within a workflow
  toggleTaskBoxes(element) {
    if (element.classList.contains("d-none")) {
      const checkboxes = element.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(checkbox => {
        checkbox.checked = false;
      });
    } else {
      const checkboxes = element.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(checkbox => {
        checkbox.checked = true;
      });
    }
  }

  // Set the active tab and apply a blue bottom border
  setActiveTab(activeTab) {
    this.accountWorkflowsLinkTarget.classList.remove("job-workflow__tab-title");
    this.preloadedWorkflowsLinkTarget.classList.remove("job-workflow__tab-title");
    activeTab.classList.add("job-workflow__tab-title");
  }

  checkIfBadgePrimary() {
    const badges = this.element.querySelectorAll('.badge-primary');
    const noWorkflowsDiv = document.getElementById('no-category-accounts');
    noWorkflowsDiv.classList.toggle("d-none", badges.length);
  }

  toggleCategoryWorkflowList(category, targetBadge) {
    const accountWorkflowDiv = this.accountWorkflowsTarget.querySelector(`#account-workflows-container .category-${category}`);
    const preloadedWorkflowDiv = this.preloadedWorkflowsTarget.querySelector(`#preloaded-workflows-container .category-${category}`);
    if (targetBadge.classList.contains("badge-primary")) {
      accountWorkflowDiv.classList.remove("d-none");
      preloadedWorkflowDiv.classList.remove("d-none");
    } else {
      accountWorkflowDiv.classList.add("d-none");
      preloadedWorkflowDiv.classList.add("d-none");
    }
  }
}
