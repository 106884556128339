import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

export default class extends Controller {
  static targets = [
    "option",
    "current",
    "oneTime",
    "oneTimeFormField",
    "currentHolder",
    "oneTimeHolder",
    "currentDataHolder",
    "defaultToggle",
    "interviewerDropdown",
    "callink",
    "availabilityToggle",
    "disabledAvailabilityTimeslot",
    "enabledAvailabilityTimeslot",
    "timezoneDisplay",
    "daysInFutureSection",
    "currentAvailabilityDescription",
    "currentAvailabilityMultipleInterviewers"
  ];

  static values = {
    useOneTime: Boolean,
    appId: String,
    interviewer: String,
    currentUserId: Number
  }

  connect() {
    this.updateCurrentAvailability = this.debounce(this.updateCurrentAvailability.bind(this), 100);
    // set up default
    if(!this.hasOneTimeFormFieldTarget)
      return;
    var defaultTab = this.useOneTimeValue ? 'one_time' : 'current_availability';
    this.handleLoad(defaultTab);
    if (this.hasInterviewerValue) {
      if (this.userIsInList(this.interviewerValue)) {
        this.setCurrentInterviewersAvailability(this.interviewerValue);
      } else {
        this.disableAvailability();
      }
    } else {
      this.setDefaultInterviewer();
    }
  }

  handleSelection(event) {
    this.processLocationType(event.currentTarget.getAttribute("for"));
  }

  handleLoad(location) {
    this.processLocationType(location);
  }

  processLocationType(location) {
    this.clearPreviousChoice();
    switch (location) {
      case "current_availability":
        this.handleCurrent();
        this.defaultToggleTarget.disabled = false;
        break;
      case "one_time":
        this.handleOneTime();
        this.defaultToggleTarget.checked = false;
        this.defaultToggleTarget.disabled = true;
        break;
      default:
        this.handleCurrent();
        this.defaultToggleTarget.disabled = false;
        break;
    }
  }

  handleCurrent() {
    this.oneTimeFormFieldTarget.value = false;
    this.currentTarget.classList.add("active");
    this.currentHolderTarget.style.display = "block";
    this.oneTimeHolderTarget.style.display = "none";
    this.daysInFutureSectionTarget.style.display = "block";
  }

  handleOneTime() {
    this.oneTimeFormFieldTarget.value = true;
    this.oneTimeTarget.classList.add("active");
    this.currentHolderTarget.style.display = "none";
    this.oneTimeHolderTarget.style.display = "block";
    this.daysInFutureSectionTarget.style.display = "none";
  }

  clearPreviousChoice() {
    this.optionTargets.forEach((option) => {
      option.classList.remove("active");
    });
  }

  updateCurrentAvailability(event) {
    this.setCurrentInterviewersAvailability(event.target.value);
  }

  setCurrentInterviewersAvailability(interviewer_id) {
    let count = this.selectedCount();
    if(count < 1){
      this.processLocationType("current_availability");
      this.disableAvailability();
      this.hideMultipleInterviewersMessage();
      return;
    }

    if(this.selectedCount() > 1){
      this.disableCurrentAvailabilityData();
      this.showMultipleInterviewersMessage();
      return;
    }

    axios.get("/manage/apps/" + this.appIdValue + "/autopilot/current_availability?user_id=" + interviewer_id).then((resp) => {
      if(interviewer_id == this.interviewerDropdownTarget.value && this.selectedCount() == 1){
        this.currentDataHolderTarget.innerHTML = resp.data
        this.toggleCalLinks();
        this.enableAvailability();
        this.hideMultipleInterviewersMessage();
      }
    });
  }

  setDefaultInterviewer(){
    if (this.userIsInList(this.currentUserIdValue)){
      this.interviewerDropdownTarget.value = this.currentUserIdValue;
      this.setCurrentInterviewersAvailability(this.currentUserIdValue);
    }
  }

  toggleCalLinks(){
    if(this.interviewerDropdownTarget.value == this.currentUserIdValue.toString()){
      this.callinkTargets.forEach(function(link){
        link.style.display = 'block';
      })
    } else {
      this.callinkTargets.forEach(function(link){
        link.style.display = 'none';
      })
    }
  }

  showMultipleInterviewersMessage(){
    if (!this.hasCurrentAvailabilityMultipleInterviewersTarget) return;
    this.currentAvailabilityMultipleInterviewersTarget.style.display = "block";
    this.currentAvailabilityDescriptionTarget.style.display = "none";
  }

  hideMultipleInterviewersMessage(){
    if (!this.hasCurrentAvailabilityMultipleInterviewersTarget) return;
    this.currentAvailabilityMultipleInterviewersTarget.style.display = "none";
    this.currentAvailabilityDescriptionTarget.style.display = "block";
  }

  disableCurrentAvailabilityData() {
    this.currentDataHolderTarget.classList.add("disabled");
    this.timezoneDisplayTarget.style.display = "none";
    this.disabledAvailabilityTimeslotTargets.forEach(element => element.style.display = "block");
    this.enabledAvailabilityTimeslotTargets.forEach(element => element.style.display = "none");
  }

  disableAvailability() {
    this.disableCurrentAvailabilityData();
    this.currentTarget.classList.add("disabled");
    this.oneTimeTarget.classList.add("disabled");
    this.availabilityToggleTarget.classList.add("disabled-field");
  }

  enableAvailability() {
    this.currentTarget.classList.remove("disabled");
    this.oneTimeTarget.classList.remove("disabled");
    this.currentDataHolderTarget.classList.remove("disabled");
    this.availabilityToggleTarget.classList.remove("disabled-field");

    this.enabledAvailabilityTimeslotTargets.forEach(element => element.style.display = "block");
    this.disabledAvailabilityTimeslotTargets.forEach(element => element.style.display = "none");
    this.timezoneDisplayTarget.style.display = "inline-block";
  }

  userIsInList(user_id) {
    return this.interviewerDropdownTarget.innerHTML.indexOf(`value=\"${parseInt(user_id)}\"`) > -1
  }

  selectedCount() {
    return [...this.interviewerDropdownTarget.options].filter(option => option.selected).length;
  }

  debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }
}
