import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  // Define targets for the search container (bar), input, and results dropdown.
  static targets = ["bar", "input", "results"]

  connect() {
    this.timeout = null;
  }

  toggle(event) {
    event.preventDefault();
    // Toggle the search input container (the bar)
    if (this.barTarget.style.display === "none" || this.barTarget.style.display === "") {
      this.barTarget.style.display = "block";
      setTimeout(() => this.inputTarget.focus(), 100);
    } else {
      // Hide the container and clear input/results
      this.barTarget.style.display = "none";
      this.inputTarget.value = "";
      this.resultsTarget.innerHTML = "";
      this.resultsTarget.style.display = "none";
    }
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const query = this.inputTarget.value;
      axios.get("/super/partnerhub_searches.json", {
        params: { q: query }
      })
      .then(response => {
        // Insert the returned HTML strings (pre-rendered via a rails partial) directly.
        this.resultsTarget.innerHTML = response.data.html;
        this.resultsTarget.style.display = "block";
      })
      .catch(error => {
        console.error("Search error:", error);
      });
    }, 300); // Debounce for 300ms
  }
}
