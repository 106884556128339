import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { locationsDataUrl: String };
  static targets = ["map"];

  connect() {
    (async () => {
      // Fetch the base map
      const topology = await fetch(
        "https://code.highcharts.com/mapdata/countries/us/us-all.topo.json"
      ).then((response) => response.json());

      // Fetch the geocoded locations data
      const locationsData = await fetch(
        this.locationsDataUrlValue
      ).then((response) => response.json());

      // Initialize the chart
      Highcharts.mapChart(this.mapTarget, {
        chart: {
          map: topology
        },

        credits: {
          enabled: false
        },

        title: {
          text: ""
        },

        accessibility: {
          description: "Map of activated locations based on ZIP code."
        },

        mapNavigation: {
          buttonOptions: {
            align: "right"
          },
          enabled: true,
          enableMouseWheelZoom: false
        },

        tooltip: {
          headerFormat: "",
          pointFormat: "<b>{point.name}</b><br>{point.num}"
        },

        series: [
          {
            name: "United States of America",
            borderColor: "#A0A0A0",
            nullColor: "rgba(230, 230, 230, 0.3)",
            showInLegend: false
          },
          {
            // Specify points using lat/lon
            type: "mappoint",
            name: "Activated Locations",
            dataLabels: {
              enabled: false
            },
            color: "rgba(37, 160, 45, 0.75)",
            data: locationsData
          }
        ]
      });
    })();
  }
}
