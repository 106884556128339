import { Controller } from "@hotwired/stimulus";
import { toastSuccess, toastDanger } from "../manage/toasts_controller";

export default class extends Controller {
  static targets = [
    "form",
    "rejectReason",
    "sendRejection",
    "renderContent",
    "satisfactionContent",
    "deactivateContent",
    "deactivateAppsCount",
    "finishCloseContent",
    "dropdownContent",
    "dropdownOption",
    "searchInput",
    "hiringGoalStatus",
    "hireCountInput",
    "careerplugOption",
    "selectedCandidatesBadges",
    "otherJobSources",
    "sourceDropdownContent",
    "didNotHireOption",
    "dropdownNoHire",
    "noHireReason",
    "hxInfo",
    "commentInfo"
  ];

  static values = { applicants: Number }

  closeHandler(evt) {
    if (evt.key === "Escape") {
      this.resetDrawerContent();
      $(this.element).modal("hide");
    }
  }

  toggleHxInfo(event) {
    if (event.target.value === 'no_qualified_applicants') {
      this.hxInfoTarget.classList.add("show");
      this.hxInfoTarget.classList.remove("hide");
    } else {
      this.hxInfoTarget.classList.add("hide");
      this.hxInfoTarget.classList.remove("show");
    }
  }

  toggleCommentInfo(event) {
    if (event.target.value) {
      this.commentInfoTarget.classList.add("show");
      this.commentInfoTarget.classList.remove("hide");
    }
  }

  toggleContent(event) {
    if (this.careerplugOptionTarget.checked) {
      this.dropdownContentTarget.classList.add("show");
      this.dropdownContentTarget.classList.remove("hide");

      this._toggleHiringGoalsVisibility(true);
    } else {
      this.dropdownContentTarget.classList.add("hide");
      this.dropdownContentTarget.classList.remove("show");
    }

    if (this.didNotHireOptionTarget.checked) {
      this.dropdownNoHireTarget.classList.add("show");
      this.dropdownNoHireTarget.classList.remove("hide");
    } else {
      this.dropdownNoHireTarget.classList.add("hide");
      this.dropdownNoHireTarget.classList.remove("show");
    }

    if (event.target.value === "outside_careerplug") {
      this.otherJobSourcesTarget.classList.add("show");
      this.otherJobSourcesTarget.classList.remove("hide");
    } else {
      this.otherJobSourcesTarget.classList.add("hide");
      this.otherJobSourcesTarget.classList.remove("show");
    }
  }

  toggleDropdown(event) {
    const dropdownContainer = event.target.closest('.custom-dropdown-container');
    if (!dropdownContainer) {
      console.error("Dropdown container not found.");
      return;
    }

    const dropdownContent = dropdownContainer.querySelector('.dropdown-content');

    if (!dropdownContent) {
      console.error("Dropdown content not found inside the container.");
      return;
    }

    if (dropdownContent.classList.contains("show")) {
      this.hideDropdown(dropdownContent);
    } else {
      document.querySelectorAll('.dropdown-content.show').forEach(content => {
        this.hideDropdown(content);
      });

      dropdownContent.classList.add("show");
    }
  }

  hideDropdown(content) {
    content.classList.remove("show");
  }

  closeOnOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      document.querySelectorAll('.dropdown-content.show').forEach(content => {
        this.hideDropdown(content);
      });
    }
  }

  toggleRejectFields(evt) {
    if (evt.target.value == "0") {
      this.formTarget.reset();
      evt.target.value = "0";
      this.rejectReasonTarget.disabled = true;
      this.sendRejectionTarget.disabled = true;
    } else {
      this.rejectReasonTarget.disabled = false;
      this.sendRejectionTarget.disabled = false;
    }
  }

  handleFormSuccess(evt) {
    let pg = evt.target.getAttribute("data-pg");
    let html = evt.detail[0]["html"];
    let jobName = evt.target.getAttribute("data-job-name");
    let isMobile = document.body.classList.contains("layout--mobile");
    let jobId = evt.target.getAttribute("data-job-id");
    let redirectPath = evt.detail[0]["redirect_path"]; 

    if (pg === "index" && !isMobile) {
      let jobRow = document.getElementById(`job_${jobId}`);
      if (jobRow) {
        jobRow.outerHTML = html;
      }
    } else if (pg === "index") {
      let mobileJobRow = document.querySelector(`.div__index-mobile-list[data-id="${jobId}"]`);
      if (mobileJobRow) {
        mobileJobRow.outerHTML = html;
      }
    } else if (pg === "profile") {
      let topBar = document.getElementById("jobs-show__top-bar");
      if (topBar) {
        topBar.outerHTML = html;
      }
    }
    let jqueryEl = $(this.element);
    if (jqueryEl.hasClass("modal")) {
      jqueryEl.modal("hide");
    } else {
      redirectPath ? this.closeAllDrawers() : this.closeInsightDrawer();
    }

    if (redirectPath) {
      sessionStorage.setItem('toast_title', `${jobName} closed`);
      sessionStorage.setItem('toast_message', `${jobName} will no longer appear on job boards or your careers page.`);
      window.location.href = redirectPath;
    } else {
      toastSuccess({
        title: `${jobName} closed`,
        body: `${jobName} will no longer appear on job boards or your careers page.`
      });
    }
  }

  handleFormError(evt) {
    let [response, , xhr] = evt.detail;

    if (xhr.status >= 500) {
      toastDanger({ title: "Something went wrong", body: "Please try again." });
      return;
    }
    this.formTarget.outerHTML = response.html;
  }

  updateHiringGoal(event) {
    const formElement = event.target.closest("form");
    const jobId = formElement.dataset.jobId;
    const hiringGoalElement = document.getElementById(`hiring_goal_status_${jobId}`);
    if (!hiringGoalElement) { return; }

    const selectedCount = event.target.selectedOptions.length;
    const hiringCounter = hiringGoalElement.querySelector(".hiring-counter");
    if (hiringCounter) {
      hiringCounter.textContent = selectedCount;
    }
  }

  updateApplicantCounter(event) {
    const totalOptionsCount = event.target.options.length;
    const selectedOptionsCount = event.target.selectedOptions.length;
    this.applicantsValue = totalOptionsCount - selectedOptionsCount;
    this.deactivateAppsCountTarget.textContent = this.applicantsValue;
  }

  handleContinue(event) {
    const continueButton = event.target;
    const currentStage = continueButton.getAttribute("data-stage");

    if (currentStage === "hiring" && this._isRenderContentVisible()) {
      this._toggleVisibility(this.renderContentTarget, false);
      this._toggleVisibility(this.satisfactionContentTarget, true);
      continueButton.setAttribute("data-stage", "satisfaction");
    } else if (currentStage === "satisfaction" && this._isSatisfactionContentVisible() && this.applicantsValue > 0) {
      this._toggleVisibility(this.satisfactionContentTarget, false);
      this._toggleVisibility(this.deactivateContentTarget, true);
      continueButton.setAttribute("data-stage", "deactivate-applicants");
      continueButton.textContent = "Close Job";
    } else if (currentStage === "satisfaction" && this._isSatisfactionContentVisible()) {
      this._toggleVisibility(this.satisfactionContentTarget, false);
      this._toggleVisibility(this.finishCloseContentTarget, true);
      continueButton.setAttribute("data-stage", "finish-close");
      continueButton.textContent = "Close Job";
    } else if (currentStage === "deactivate-applicants" || currentStage === "finish-close") {
      continueButton.setAttribute("type", "submit");
    }
  }

  incrementHireCount() {
    let currentCount = parseInt(this.hireCountInputTarget.value, 10);
    this.hireCountInputTarget.value = currentCount + 1;
  }

  decrementHireCount() {
    let currentCount = parseInt(this.hireCountInputTarget.value, 10);
    if (currentCount > 0) {
      this.hireCountInputTarget.value = currentCount - 1;
    }
  }

  closeAllDrawers() {
    const drawerPortalCtrls = this.drawerPortalControllers;
    drawerPortalCtrls.forEach(drawerPortalCtrlr => {
      drawerPortalCtrlr.hide();
    });
  }

  closeInsightDrawer() {
    const drawerPortalCtrls = this.drawerPortalControllers;
    drawerPortalCtrls.pop().hide();
  }

  get drawerPortalControllers() {
    let drawerElements = document.querySelectorAll(".voyager-drawer__container--open");

    return Array.from(drawerElements).map(drawerEl => {
      let drawerPortalEl = drawerEl.parentElement;
      return this.application.getControllerForElementAndIdentifier(
        drawerPortalEl,
        "drawer-portal"
      );
    });
  }

  resetDrawerContent() {
    const continueButton = this.element.querySelector(".close-job-drawer .voyager-drawer__footer button.btn.btn-primary");
    continueButton.setAttribute("data-stage", "hiring");
    continueButton.textContent = "Continue";
    this.formTarget.reset();
    this._toggleVisibility(this.renderContentTarget, true);
    this._toggleVisibility(this.dropdownContentTarget, false);
    this._toggleVisibility(this.dropdownNoHireTarget, false);
    this._toggleVisibility(this.otherJobSourcesTarget, false);
    this._toggleVisibility(this.satisfactionContentTarget, false);
    this._toggleVisibility(this.deactivateContentTarget, false);
    this._toggleVisibility(this.finishCloseContentTarget, false);
  }

  _isRenderContentVisible() {
    return this.hasRenderContentTarget && !this.renderContentTarget.classList.contains("hide");
  }

  _isSatisfactionContentVisible() {
    return this.satisfactionContentTarget && this.satisfactionContentTarget.classList.contains("show");
  }

  _toggleVisibility(element, show) {
    if (element) {
      element.classList.toggle("show", show);
      element.classList.toggle("hide", !show);
    }
  }

  _toggleHiringGoalsVisibility(show) {
    if (this.hasHiringGoalStatusTarget) {
      this._toggleVisibility(this.hiringGoalStatusTarget, true);
    }
  }
}
