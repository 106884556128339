import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["available", "item", "menu", "search", "selectedTemplate"];
  static values = { lazyPath: String };

  initialize() {
    if (this.hasLazyPathValue) {
      this.search = debounce(this.search.bind(this), 300);
    }
  }

  async search(event) {
    this.menuTarget.innerHTML = "";
    if (event.target.value.length > 2) {
      this.openMenu();
      const results = await this.fetchResults(event.target.value);
      if (results.length > 0) {
        results.forEach((item) => {
          this.addMenuItem(item);
        });
      }
    }
  }

  async fetchResults(search) {
    if (this.hasLazyPathValue) {
      // server-side search and populated available items
      this.availableTarget.innerHTML = "";
      await fetch(`${this.lazyPathValue}?search=${search}`)
        .then((response) => response.json())
        .then((data) => {
          data.forEach((item) => {
            this.addAvailableItem(item);
          });
        });
      return this.itemTargets;
    } else {
      // client-side search using all available items
      return this.itemTargets.filter((item) => {
        return item
          .getAttribute("data-chooser-select-label")
          .match(new RegExp(search, "i"));
      });
    }
  }

  resetMenu() {
    this.menuTarget.innerHTML = "";
    this.itemTargets.forEach((item) => {
      this.addMenuItem(item);
    });
  }

  showMenu() {
    if (this.itemTargets.length < 1 || this.itemTargets.length > 1000) return;

    this.resetMenu();
    this.openMenu();
  }

  openMenu() {
    this.menuTarget.classList.add("show");
    const bounds = this.menuTarget.getBoundingClientRect();
    if (bounds.x + bounds.width > document.body.clientWidth) {
      this.menuTarget.classList.add("right");
    } else {
      this.menuTarget.classList.remove("right");
    }
  }

  hideMenu(event) {
    const related = event.relatedTarget;
    // added check for placeholder since Safari blur does not send the same dom for relatedTarget
    // if placeholder is present the menu has lost focus and should be hidden
    if (
      related &&
      (related.hasAttribute("data-chooser-select-value") ||
        !related.hasAttribute("placeholder"))
    )
      return;

    this.menuTarget.classList.remove("show");
  }

  resetSearch() {
    this.searchTarget.value = "";
  }

  addAvailableItem(node) {
    if (this.existingInputWithValue(node.value)) {
      return;
    }

    const item = document.createElement("span");
    item.setAttribute("data-chooser-select-target", "item");
    item.setAttribute("data-chooser-select-label", node.label);
    item.setAttribute("data-chooser-select-value", node.value);
    this.availableTarget.appendChild(item);
  }

  addMenuItem(node) {
    const item = document.createElement("button");
    item.innerHTML = node.getAttribute("data-chooser-select-label");
    item.setAttribute("class", "dropdown-item");
    item.setAttribute("type", "button");
    item.setAttribute(
      "data-chooser-select-value",
      node.getAttribute("data-chooser-select-value")
    );
    item.setAttribute("data-action", "click->chooser-select#selectItem");
    this.menuTarget.appendChild(item);
  }

  selectItem(event) {
    this.addSelectedButton(event.target);
    this.menuTarget.classList.remove("show");
    this.resetSearch();
    this.removeFromAvailable(event.target);
    this.element.dispatchEvent(
      new CustomEvent("chooser_select:change", {
        bubbles: true,
        cancelable: true
      })
    );
  }

  addSelectedButton(node) {
    const item = document.createElement("div");
    item.innerHTML = this.selectedTemplateTarget.innerHTML;
    this.searchTarget.parentNode.insertAdjacentElement("beforebegin", item);
    const input = item.querySelector("input");
    input.setAttribute("value", node.getAttribute("data-chooser-select-value"));
    const button = item.querySelector("button");
    button.setAttribute("data-chooser-select-label", node.innerHTML);
    button.setAttribute(
      "data-chooser-select-value",
      node.getAttribute("data-chooser-select-value")
    );
    button.setAttribute("data-action", "click->chooser-select#remove");
    const label = button.querySelector("span.label");
    label.innerHTML = node.innerHTML;
  }

  remove(event) {
    const node = event.target.closest("div");
    this.generateAvailableItem(node.querySelector("button"));
    node.parentNode.removeChild(node);
  }

  generateAvailableItem(node) {
    const item = document.createElement("span");
    item.setAttribute(
      "data-chooser-select-label",
      node.getAttribute("data-chooser-select-label")
    );
    item.setAttribute(
      "data-chooser-select-value",
      node.getAttribute("data-chooser-select-value")
    );
    item.setAttribute("data-chooser-select-target", "item");
    this.availableTarget.appendChild(item);
  }

  removeFromAvailable(node) {
    this.itemTargets.forEach((avail) => {
      if (
        avail.getAttribute("data-chooser-select-value") ===
        node.getAttribute("data-chooser-select-value")
      ) {
        avail.parentNode.removeChild(avail);
      }
    });
  }

  existingInputWithValue(value) {
    let inputWrapper = this.searchTarget.parentNode.parentNode;
    let existingInput = inputWrapper.querySelector(`input[value="${value}"]`);

    if (existingInput) {
      return true;
    }

    return false;
  }
}
