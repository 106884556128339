import { Controller } from "@hotwired/stimulus";
import { pendoTrackEvent } from "../../utils/pendo_track_event";

export default class extends Controller {
  static targets = ["track", "counter", "leftButton", "rightButton"];

  connect() {
    this.index = 0;
    this.totalItems = this.trackTarget.children.length;
    this._update();
    this._updateCounter();
    this._updateButtons();
  }

  shiftLeft() {
    if (this._atStart()) return;
    this.index -= 1;
    this._update();
    this._updateCounter();
    this._updateButtons();
  }

  shiftRight() {
    if (this._atEnd()) return;
    this.index += 1;
    this._update();
    this._updateCounter();
    this._updateButtons();
  }

  disableButtons(event) {
    const parent = event.target.closest(".button-wrapper");
    parent.innerHTML = '<i class="fad fa-spinner-third fa-spin"></i> ...';
  }

  /**
   * Checks if the carousel is at the start.
   * @private
   * @returns {boolean}
   */
  _atStart() {
    return this.index === 0;
  }

  /**
   * Checks if the carousel is at the end.
   * @private
   * @returns {boolean}
   */
  _atEnd() {
    return this.index === this.totalItems - 1;
  }

  _atStart() {
    this.index == 0;
  }
  /**
   * Updates the position of the track element based on the current index.
   * @private
   */
  _update() {
    const itemWidth = this.trackTarget.children[0].offsetWidth;
    this.trackTarget.style.transform = `translateX(-${
      this.index * itemWidth
    }px)`;
    this._trackViewed();
  }

  /**
   * Updates the counter element to reflect the current position in the carousel.
   * @private
   */
  _updateCounter() {
    if (this.hasCounterTarget) {
      this.counterTarget.textContent = `${this.index + 1}/${this.totalItems}`;
    }
  }

  /**
   * Sets the visibility of a button based on a condition.
   * @private
   * @param {HTMLElement} button - The button element to update.
   * @param {boolean} isVisible - Whether the button should be visible.
   */
  _setButtonVisibility(button, isVisible) {
    button.style.display = isVisible ? "flex" : "none";
  }

  /**
   * Updates the visibility of the navigation buttons based on the current position in the carousel.
   * @private
   */
  _updateButtons() {
    if (this.hasLeftButtonTarget) {
      this._setButtonVisibility(this.leftButtonTarget, this.index > 0);
    }

    if (this.hasRightButtonTarget) {
      this._setButtonVisibility(
        this.rightButtonTarget,
        this.index < this.totalItems - 1
      );
    }
  }

  _trackViewed() {
    const currentSuggestion = this.trackTarget.children[this.index];
    const suggestionPendoId = currentSuggestion.getAttribute('data-pendo');
    const suggestion = this._suggestionName(suggestionPendoId);
    pendoTrackEvent(`${suggestion} | Job Index Smart Suggestion Viewed`, {
      suggestionPendoId: suggestionPendoId,
      topSuggestion: this.index === 0
    });
  }

  _suggestionName(suggestionPendoId) {
    const cleanedSuggestionId = suggestionPendoId.replace(/_+/g, ' ');
    let parts = cleanedSuggestionId.split(' ');
    parts.pop();
    return parts.join(' ');
  }
}
