import { Controller } from "@hotwired/stimulus";
import { toastSuccess } from "../manage/toasts_controller";

export default class extends Controller {

  connect() {
    const toastTitle = sessionStorage.getItem('toast_title');
    const toastMessage = sessionStorage.getItem('toast_message');

    if (toastTitle && toastMessage) {
      toastSuccess({
        title: toastTitle,
        body: toastMessage
      });

      sessionStorage.removeItem('toast_title');
      sessionStorage.removeItem('toast_message');
    }
  }
}
