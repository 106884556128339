import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ['inputSelect']

  static values = {
    inSelectAll: Boolean
  }

  // 0 represents Select All in the dropdown.

  connect(){
    let picker = $("#interviewers-selector.selectpicker").selectpicker()
    $(picker).on("changed.bs.select", (event, clickedIndex, newValue, oldValue) => {
      if(clickedIndex == '0'){
        if(this.inSelectAllValue){
          this.checkNone()
          this.inSelectAllValue = false
        } else {
          this.checkAll()
          this.inSelectAllValue = true
        }
      } else {
        if(this.inSelectAllValue){
          this.inSelectAllValue = false
          picker.find('option[value="0"]').prop('selected', false)
          picker.selectpicker('refresh')
        }
      }
      this.showInterviews()
    })
  }

  showInterviews(){
    this.requestInterviews()
  }

  requestInterviews(event){
    if (event) { event.preventDefault() }
    axios.get("/manage/user_interviews?user_ids=" + this.whichIds().join(',')).then((resp) => {
      document.getElementById('interview-content').innerHTML = resp.data
    });
  }

  whichIds(){
    if(this.selectedIds().includes('0')){
      return this.allIds()
    } else {
      return this.selectedIds()
    }
  }

  selectedIds(){
    var ids = []
    for(let item of this.inputSelectTarget.selectedOptions) {
      ids.push(item.value)
    }
    return ids
  }


  allIds(){
    var ids = []
    for(let id of this.inputSelectTarget.options) {
      if (id.value == '0'){
        continue
      }
      ids.push(id.value)
    }
    return ids
  }

  checkAll(){
    $("#interviewers-selector.selectpicker").selectpicker("selectAll");
    let button = $("#dashboard-interviewers").find('.filter-option-inner-inner');
    // Ensure this fires after selectpicker is done, or selectpicker will just override this again
    setTimeout(() => {
      button.text('All');
    }, 10);
  }

  checkNone(){
    $("#interviewers-selector.selectpicker").selectpicker("deselectAll");
  }
}
