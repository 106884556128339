import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import * as pdfjs from "pdfjs-dist/build/pdf.js";
import PdfjsWorker from "worker-loader?inline=true!pdfjs-dist/build/pdf.worker.js";
import { showSpinner, hideSpinner } from "../manage/loading_spinner_controller";

if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}

export default class extends Controller {
  static targets = [
    "form", "modal", "originalName", "newName",
    "warning", "basicWarning", "replaceButton"
  ];

  confirmFileSelection(event) {
    event.preventDefault();
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const pdf = await pdfjs.getDocument({
          data: await file.arrayBuffer()
        }).promise;

        await this.updateMessage(file.name, pdf.numPages);

        $(this.modalTarget).modal({ backdrop: "static", keyboard: false });
        $(this.modalTarget).modal("show");
        this.fileEvent = event;
      } catch (error) {
        console.error("Error reading PDF:", error);
        this.formTarget.value = '';
        hideSpinner();
      }
    };
    reader.readAsArrayBuffer(file);
  }

  async updateMessage(newFileName, newPageCount) {
    const originalName = this.formTarget.dataset.originalName;
    const originalPages = parseInt(this.formTarget.dataset.originalPages);

    this.originalNameTarget.textContent = originalName;
    this.newNameTarget.textContent = newFileName;

    if (newPageCount < originalPages) {
      this.warningTarget.classList.remove('d-none');
      this.basicWarningTarget.classList.add('d-none');
    } else {
      this.warningTarget.classList.add('d-none');
      this.basicWarningTarget.classList.remove('d-none');
    }
  }

  cancel() {
    $(this.modalTarget).modal("hide");
    this.formTarget.value = '';
  }

  confirm() {
    showSpinner({
      element: "body",
      title: "Replacing PDF",
      message: "Please wait while we process your document...",
      timeout: 0
    });

    $(this.modalTarget).modal("hide");
    this.replaceButtonTarget.disabled = true;

    if (this.fileEvent) {
      const form = this.fileEvent.target.form;
      const replacingPdfField = form.querySelector('input[name$="[replacing_pdf]"]');
      if (replacingPdfField) {
        replacingPdfField.value = "true";
      }

      new Promise((resolve) => {
        form.addEventListener('ajax:complete', resolve, { once: true });
        form.requestSubmit();
      }).then(() => {
        hideSpinner();
      });
    }
  }

  disconnect() {
    this.fileEvent = null;
    hideSpinner();
    $(this.modalTarget).modal("hide");
  }
}
