import { Controller } from "@hotwired/stimulus";
import { showToast, toastDanger } from "../manage/toasts_controller";

export default class extends Controller {
  toggleButtonText(status) {
    const attribute = `data-upload-${status}`,
      content = this.buttonTarget.getAttribute(attribute);

    this.setOriginalButtonText(content);

    if (!this.loading) {
      this.buttonTarget.innerHTML = content;
    }
  }

  toggleLoading(status) {
    this.loading = status;

    if (!this.buttonTarget.hasAttribute("data-original-button-content")) {
      this.setOriginalButtonText(this.buttonTarget.innerHTML);
    }

    if (this.loading == true) {
      this.deleteTarget.classList.add("disabled");
      this.buttonTarget.classList.add("disabled");
      this.buttonTarget.innerHTML = this.uploadContent;
    } else if (this.loading == false) {
      this.deleteTarget.classList.remove("disabled");
      this.buttonTarget.classList.remove("disabled");
      this.buttonTarget.innerHTML = this.buttonTarget.getAttribute(
        "data-original-button-content"
      );
    }
  }

  displayToast(data) {
    if (data.toasts == undefined || data.toasts == null) return;

    showToast(data.toasts);
  }

  largeFileError(status) {
    if (status != 413) return;

    this.showError({ message: 'must be less than 10 megabytes in size' });
  }

  errorToast() {
    toastDanger({
      title: "Something went wrong",
      body: "There was a problem uploading your image. Please try again."
    });
  }

  showError(data) {
    if (!this.hasErrorTarget) return;
    if (data.message == undefined || data.message == null) return;

    this.errorTarget.classList.add("d-block");
    this.errorTarget.innerHTML = data.message;
  }

  hideError() {
    if (!this.hasErrorTarget) return;

    this.errorTarget.classList.remove("d-block");
    this.errorTarget.innerHTML = "";
  }

  clearFileField() {
    this.inputTarget.value = "";
  }

  setOriginalButtonText(content) {
    this.buttonTarget.setAttribute(
      "data-original-button-content",
      content
    );
  }

  get uploadUrl() {
    return this.element.dataset.uploadUrl;
  }

  get uploadContent() {
    if (this.element.dataset.uploadContent) {
      return this.element.dataset.uploadContent;
    } else {
      return '<i class="fad fa-spinner-third fa-spin"></i> Uploading...';
    }
  }
}
