import UploadUtils from "./upload_utils_controller";
import axios from "axios";

export default class extends UploadUtils {
  static targets = ["input", "button", "preview", "delete", "error", "defaultContent"];
  
  uploadImage() {
    if (this.inputTarget.files.length == 0) return;

    this.toggleLoading(true);
    this.hideError();

    let formData = new FormData();
    formData.append("image", this.inputTarget.files[0]);

    axios
      .post(this.uploadUrl, formData)
      .then((res) => {
        this.createPreviewImage(res.data.url);
        this.deleteTarget.classList.remove("d-none");
        this.toggleButtonText(true);
        this.displayToast(res.data);
      })
      .catch((err) => {
        this.largeFileError(err.response.status);
        this.showError(err.response.data);
        this.errorToast();
      })
      .finally(() => {
        this.clearFileField();
        this.toggleLoading(false);
      });
  }

  destroyImage(event) {
    event.preventDefault();
    this.hideError();
    this.deleteTarget.classList.add("disabled");

    axios
      .delete(this.uploadUrl)
      .then((res) => {
        this.destroyPreviewImage();
        this.deleteTarget.classList.add("d-none");
        this.toggleButtonText(false);
        this.displayToast(res.data);
      })
      .catch((err) => {
        console.log("err", err);
        this.errorToast();
      })
      .finally(() => {
        this.deleteTarget.classList.remove("disabled");
      });
  }

  createPreviewImage(url) {
    let img = document.createElement("img");
    img.src = url;
    this.previewTarget.innerHTML = "";
    this.previewTarget.appendChild(img);
    this.previewTarget.classList.remove("d-none");
  }

  destroyPreviewImage() {
    if (this.hasDefaultContentTarget) {
      this.previewTarget.innerHTML = this.defaultContentTarget.innerHTML;
    } else {
      this.previewTarget.innerHTML = "";
    }
    this.previewTarget.classList.remove("d-none");
  }
}
