import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    this.modalElement = this.element.querySelector("#shareJobModal");
    const newJobData = this._parseNewJobData();

    this.processNewJob(newJobData)

    $("#shareJobModal").on("show.bs.modal", (event) => {
      const trigger = event.relatedTarget;

      if (trigger) {
        this.element.dataset.newJob = "false";
        this._toggleModalContent();
        this.initExistingJobModal(trigger);
      }
    });
  }

  processNewJob(newJobData){
    if (!this.modalElement) return;

    if (this.modalElement.dataset.modalShow === "true") {
      this.element.dataset.newJob = "true"; 
      this.show();
      this.initNewJobModal(newJobData)
    }
  }

  initNewJobModal(newJobData) {
    this._toggleModalContent();
    if (newJobData) {
        this._replaceModalContent(`/manage/jobs/${newJobData.id}/resources/easy_share_modal`);
    }
  }

  initExistingJobModal(trigger) {
      this._toggleModalContent(); 
      const jobId = trigger.dataset.jobId;
      if (jobId) {
          const url = `/manage/jobs/${jobId}/resources/easy_share_modal`;
          this._replaceModalContent(url);
      }
  }

  show() {
      $("#shareJobModal").modal("show");
  }

  dismiss() {
    $("#shareJobModal").modal("hide");
  }

  _toggleModalContent() {
      const isNewJob = this.element.dataset.newJob === "true";
      const heroSection = this.element.querySelector("[data-modal-content='hero']");
      const titleSection = this.element.querySelector(".modal-title");
      const descSection = this.element.querySelector("[data-modal-content='description']");
      const heroDescSection = this.element.querySelector("[data-modal-content='hero-description']");
      
      this._toggleView(heroSection, isNewJob);
      this._toggleView(titleSection, !isNewJob);
      this._toggleView(descSection, !isNewJob);
      this._toggleView(heroDescSection, isNewJob)
  }

  _toggleView(section, isVisible) {
      if (!section) return;
      if (isVisible) {
        section.classList.add("d-block");
        section.classList.remove("d-none");
      } else {
        section.classList.add("d-none");
        section.classList.remove("d-block");
      }
  }

  _replaceModalContent(url) {
    const turboFrame = document.getElementById("easy-share-modal");
    if (!turboFrame) return
      fetch(url)
        .then(response => response.text())
        .then(html => {
          turboFrame.innerHTML = html;
        })
        .catch(error => console.error("Turbo Frame Load Error:", error));
  }

  _parseNewJobData() {
    if (!this.element.dataset.newJob) return
    try {
      const newJob = JSON.parse(this.element.dataset.newJob);
      if (newJob && newJob.id && newJob.url) {
        return { id: newJob.id, url: newJob.url };
      }
    } catch (e) {
      console.error("Invalid new job data", e);
    }
    return null;
  }
}
